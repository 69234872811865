@font-face {
  font-family: PressStart2P;
  src: url('../../Common/styles/PressStart2P.ttf');
}

.startWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: none;
}

.startLink {
  user-select: none;
  color: white;
  font-size: 5em;
  font-family: PressStart2P;
  -webkit-animation: blink 0.7s ease-in-out infinite alternate;
  -moz-animation: blink 0.7s ease-in-out infinite alternate;
  animation: blink 0.7s ease-in-out infinite alternate;
}

.reactPlayer {
  display: none;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}