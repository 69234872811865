@font-face {
  font-family: PressStart2P;
  src: url('../Common/styles/PressStart2P.ttf');
}

.container {
  background: #333;
}

.signals {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  cursor: none;
}

.timer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4em;
  font-family: PressStart2P;
  height: 100%;
  cursor: none;
  user-select: none;

  .playerName {
    color: springgreen;
    margin-bottom: 1em;
  }

  .time {
    color: white;
    display: flex;
    justify-content: space-between;
    width: 11em;
  }
}

.reactPlayer {
  display: none;
}