html {
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}