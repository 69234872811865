$black: #222;

.signal {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #222;
}

.firstSignal {
  &._0 {
    background: $black;
  }

  &._1 {
    background: darkred;
  }

  &._2 {
    background: darkred;
  }

  &._3 {
    background: darkred;
  }

  &._4 {
    background: lawngreen;
  }

  &._5 {
    background: lawngreen;
  }
}

.secondSignal {
  &._0 {
    background: $black;
  }

  &._1 {
    background: $black;
  }

  &._2 {
    background: darkred;
  }

  &._3 {
    background: darkred;
  }

  &._4 {
    background: lawngreen;
  }

  &._5 {
    background: lawngreen;
  }
}

.thirdSignal {
  &._0 {
    background: $black;
  }

  &._1 {
    background: $black;
  }

  &._2 {
    background: $black;
  }

  &._3 {
    background: darkred;
  }

  &._4 {
    background: lawngreen;
  }

  &._5 {
    background: lawngreen;
  }
}
